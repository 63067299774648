import loadScript from './load_script'

export const init = () => {
  if (typeof window !== 'undefined') {
    window.ym = window.ym || function () {
      (window.ym.a = window.ym.a || []).push(arguments)
    }
    window.ym.l = 1 * new Date()
  }
  return loadScript('https://mc.yandex.ru/metrika/tag.js')
}
