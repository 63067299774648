const loadScript = function (f, b, e, v, n, t, s) {
  if (f.fbq) return Promise.resolve()
  return new Promise((resolve, reject) => {
    n = f.fbq = function () {
      n.callMethod
        ? n.callMethod.apply(n, arguments)
        : n.queue.push(arguments)
    }
    if (!f._fbq) f._fbq = n
    n.push = n
    n.loaded = !0
    n.version = '2.0'
    n.queue = []
    t = b.createElement(e)
    t.async = !0
    t.src = v
    t.onload = t.onreadystatechange = function () {
      var t = this.readyState
      if (!t || t === 'complete' || t === 'loaded') {
        resolve()
      } else {
        reject(new Error('Failed to load script'))
      }
    }
    t.onerror = reject
    s = b.getElementsByTagName(e)[0]
    s.parentNode.insertBefore(t, s)
  })
}

export const init = () => {
  return loadScript(window, document, 'script',
    'https://connect.facebook.net/en_US/fbevents.js')
}
